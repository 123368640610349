import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';

import { DataService } from 'app/services/data-transfer/data-transfer.service';


@Injectable()
export class EditTicketsResolve implements Resolve<any> {

    constructor(private dataservice: DataService) {}

    resolve(): any {
        return JSON.parse(this.dataservice.getAdminTicketSearchEdit());
    }
}