import { Component, OnInit, Renderer, ViewChild, ElementRef, Directive } from '@angular/core';
import { ROUTES_Admin, ROUTES_Technical } from '../sidebar/sidebar.component';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs/Subscription';
import swal from 'sweetalert2';

let misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;
    private _router: Subscription;
    private href = '';

    @ViewChild('navbar-cmp') button;

    constructor(location: Location, private renderer: Renderer,
                private element: ElementRef, private router: Router) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    ngOnInit() {
        this.href = this.router.url;

        let admin_flag = this.href.includes('admin');
        if (!admin_flag) {
            this.listTitles = ROUTES_Technical.filter(listTitle => listTitle);
        } else {
            this.listTitles = ROUTES_Admin.filter(listTitle => listTitle);
        }

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
          const $layer = document.getElementsByClassName('close-layer')[0];
          if ($layer) {
            $layer.remove();
          }
        });
    }

    minimizeSidebar() {
      const body = document.getElementsByTagName('body')[0];

      if (misc.sidebar_mini_active === true) {
          body.classList.remove('sidebar-mini');
          misc.sidebar_mini_active = false;

      } else {
          setTimeout(function() {
              body.classList.add('sidebar-mini');

              misc.sidebar_mini_active = true;
          }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function() {
          window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function() {
          clearInterval(simulateWindowResize);
      }, 1000);
    }

    isMobileMenu() {
        if(window.outerWidth < 991){
            return false;
        }
        return true;
    }

    sidebarOpen(){
        let toggleButton = this.toggleButton;
        let html = document.getElementsByTagName('html')[0];

        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);

        const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
        if (window.innerWidth < 991) {
          mainPanel.style.position = 'fixed';
        }

        html.classList.add('nav-open');
        this.sidebarVisible = true;
    }

    sidebarClose() {
        let html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
        const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];

        if (window.innerWidth < 991) {
          setTimeout(function(){
            mainPanel.style.position = '';
          }, 500);
        }
    }

    sidebarToggle() {
        // let toggleButton = this.toggleButton;
        // let body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible == false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    getTitle() {
        let titlee = this.location.prepareExternalUrl(this.location.path());
        switch (titlee.slice(1)) {
            case '/admin/dashboard':
                return 'dashboard'.toUpperCase();
            case '/admin/clients/add':
                return 'Añadir Clientes'.toUpperCase();
            case '/admin/clients/edit':
                return 'Editar Clientes'.toUpperCase();
            case '/admin/clients/search':
                return 'Buscar clientes'.toUpperCase();
            case '/admin/hardware/add':
                return 'Añadir Refacciones'.toUpperCase();
            case '/admin/hardware/edit':
                return 'Editar Refacciones'.toUpperCase();
            case '/admin/hardware/search':
                return 'buscar Refacciones'.toUpperCase();
            case '/admin/contracts/add':
                return 'Añadir Contratos'.toUpperCase();
            case '/admin/contracts/edit':
                return 'Editar Contratos'.toUpperCase();
            case '/admin/contracts/search':
                return 'buscar Contratos'.toUpperCase();
            case '/admin/tickets/add':
                return 'Añadir Boletas'.toUpperCase();
            case '/admin/tickets/edit':
                return 'Editar Boletas'.toUpperCase();
            case '/admin/tickets/search':
                return 'buscar Boletas'.toUpperCase();
            case '/admin/tickets/searchRef':
                    return 'Boletas con Refacciones'.toUpperCase();
            case '/admin/tickets/bitacora':
                    return 'Bitacoras'.toUpperCase();
            case '/admin/reports/incomplete':
                return 'Reportes Incompletos'.toUpperCase();
            case '/admin/reports/complete':
                return 'Reportes Completados'.toUpperCase();
            case '/admin/reports/view':
                return 'Vista de Reporte'.toUpperCase();
            case '/admin/technicals/add':
                return 'Añadir Técnicos'.toUpperCase();
            case '/admin/technicals/edit':
                return 'Editar Técnicos'.toUpperCase();
            case '/admin/technicals/search':
                return 'buscar Técnicos'.toUpperCase();
            case '/admin/admins/add':
                return 'Añadir Admins'.toUpperCase();
            case '/admin/admins/edit':
                return 'Editar Admins'.toUpperCase();
            case '/admin/admins/search':
                return 'Buscar Admins'.toUpperCase();
            case '/admin/profile':
                return 'mi perfil'.toUpperCase();
            case '/admin/tickets/add/warning':
                return'Boleta Preventiva'.toUpperCase();
            case '/admin/tickets/add/corrective':
                return 'Boleta Correctiva'.toUpperCase();
            case '/admin/inventario/series':
                return 'Inventario Series'.toUpperCase();
            case '/admin/inventario/modelos':
                return 'Inventario Modelos'.toUpperCase();
            case '/admin/inventario/marcas':
                return 'Inventario Marcas'.toUpperCase();
            case '/admin/rastreo':
                return 'Seguimiento'.toUpperCase();

            case '/grocer/profile':
                return 'Mi Perfil'.toUpperCase();
            case '/grocer/dashboard':
                return 'Dashboard'.toUpperCase();
            case '/grocer/hardware':
                return 'Refacciones'.toUpperCase();
            case '/grocer/hardware/add':
                return 'Agregar Refacciones'.toUpperCase();
            case '/grocer/hardware/assign':
                return 'Asignar Refacciones'.toUpperCase();
            case '/grocer/hardware/search':
                return 'Buscar Refacciones'.toUpperCase();
            case '/grocer/rastreo':
                return 'Seguimiento'.toUpperCase();

            case '/supervisor/dashboard':
                return 'Dashboard'.toUpperCase();
            case '/supervisor/contracts/add':
                return 'Añadir Contratos'.toUpperCase();
            case '/supervisor/contracts/edit':
                return 'Editar Contratos'.toUpperCase();
            case '/supervisor/contracts/search':
                return 'Buscar Contratos'.toUpperCase();
            case '/supervisor/tickets/add':
                return 'Agregar contrato'.toUpperCase();
            case '/supervisor/profile':
                return 'Mi perfil'.toUpperCase();
            case '/supervisor/reports/list':
                return 'Lista de Reportes'.toUpperCase();
            case '/supervisor/reports/history':
                return 'Historial de Reportes'.toUpperCase();
            case '/supervisor/reports/view':
                return 'Visualización de Reporte'.toUpperCase();
            case '/supervisor/tickets/add':
                return 'Crear Boleta'.toUpperCase();
            case '/supervisor/tickets/history':
                return 'Historial de Boletas'.toUpperCase();
            case '/supervisor/reports/incomplete':
                return 'Reportes Incompletos'.toUpperCase();
            case '/supervisor/reports/complete':
                return 'Reportes Completados'.toUpperCase();
            case '/supervisor/reports/view':
                return 'Vista de Reporte'.toUpperCase();
            case '/supervisor/tickets/add/warning':
                return'Boleta Preventiva'.toUpperCase();
            case '/supervisor/tickets/add/corrective':
                return 'Boleta Correctiva'.toUpperCase();

            case '/technical/dashboard':
                return 'dashboard'.toUpperCase();
            case '/technical/profile':
                return 'mi perfil'.toUpperCase();
            case '/technical/reports/list':
                return 'Lista de Reportes'.toUpperCase();
            case '/technical/reports/history':
                return 'Historial de Reportes'.toUpperCase();
            case '/technical/reports/view':
                return 'Visualización de Reporte'.toUpperCase();
            case '/technical/tickets/add':
                return 'Crear Boleta'.toUpperCase();
            case '/technical/tickets/history':
                return 'Historial de Boletas'.toUpperCase();
            case '/technical/tickets/bitacorahistory':
                return 'Bitacora'.toUpperCase();
            case '/technical/reports/priority':
                return 'Reportes Con Prioridad'.toUpperCase();
            case '/technical/tickets/add/warning':
                return'Boleta Preventiva'.toUpperCase();
            case '/technical/tickets/add/corrective':
                return 'Boleta Correctiva'.toUpperCase();
        }
    }

    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }

    private goBack() {
        if (this.location.path().includes('notes')) {
            swal({
                title: '¡Precaución!',
                text: "No se puede volver atras desde esta pantalla",
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-suplidora',
                type: 'warning'
              }).catch(swal.noop)   
            return            
        }
        if (this.location.path().includes('admin/tickets/search')) { 
            this.router.navigate(['/admin/dashboard'])
            return      
        }
        if (this.location.path().includes('technical/tickets/history')) { 
            this.router.navigate(['/technical/dashboard'])
            return      
        }
        if (this.location.path() == '' && this.location.path().includes('dashboard')) {
            return
        }
        window.history.go(-1);
    }
}
