import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgxLoadingModule } from 'ngx-loading';

import { AppComponent } from 'app/app.component';

import { ComponentsModule } from 'app/components/components.module';
import { AdminLayoutComponent } from 'app/layouts/admin/admin-layout.component';
import { GrocerLayoutComponent } from 'app/layouts/grocer/grocer-layout.component';
import { SupervisorLayoutComponent } from 'app/layouts/supervisor/supervisor-layout.component';
import { TechLayoutComponent } from 'app/layouts/technical/technical-layout.component';

import { LoginComponent } from 'app/login/login.component';
import { EncriptionService } from 'app/services/encription/encription.service';

import { AuthService } from 'app/services/auth/auth.service';

import { AdminGuardService } from 'app/services/auth/admin-guard/admin-guard.service';
import { GrocerGuardService } from 'app/services/auth/grocer-guard/grocer-guard.service';
import { TechnicalGuardService } from 'app/services/auth/technical-guard/technical-guard.service';
import { SupervisorGuardService } from 'app/services/auth/supervisor-guard/supervisor-guard.service';

import { constants } from 'app/config/constants';
import { EditTicketsResolve } from 'app/admin/tickets/edit/edit-tickets.resolve';
import { DataService } from 'app/services/data-transfer/data-transfer.service';
import { ReportsService } from 'app/shared/services/reports/reports.service';
import { TicketsService } from 'app/shared/services/tickets/tickets.service';


const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    }, {
        path: 'admin',
        component: AdminLayoutComponent,
        children:
            [
                {
                    path: '',
                    loadChildren: './layouts/admin/admin-layout.module#AdminLayoutModule'
                }
            ],
        canActivate: [AdminGuardService],
        data: { role: 'admin' },
        resolve: {
            ticket: EditTicketsResolve
        }
    }, {
        path: 'supervisor',
        component: SupervisorLayoutComponent,
        children:
            [
                {
                    path: '',
                    loadChildren: './layouts/supervisor/supervisor-layout.module#SupervisorLayoutModule'
                }
            ],
        canActivate: [SupervisorGuardService],
        data: { role: 'supervisor' }
    }, {
        path: 'technical',
        component: TechLayoutComponent,
        children:
            [
                {
                    path: '',
                    loadChildren: './layouts/technical/technical-layout.module#TechLayoutModule'
                }
            ],
        canActivate: [TechnicalGuardService],
        data: { role: 'technical' }
    }, {
        path: 'grocer',
        component: GrocerLayoutComponent,
        children:
            [
                {
                    path: '',
                    loadChildren: './layouts/grocer/grocer-layout.module#GrocerLayoutModule'
                }
            ],
        canActivate: [GrocerGuardService],
        data: { role: 'grocer' }
    }, {
        path: 'login',
        component: LoginComponent
    }, {
        path: '**',
        component: LoginComponent
    }
];

@NgModule({
    imports: [
        BrowserAnimationsModule,
        ComponentsModule,
        FormsModule,
        HttpClientModule,
        HttpModule,
        NgbModule.forRoot(),
        NgxLoadingModule.forRoot({}),
        RouterModule.forRoot(AppRoutes),
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        GrocerLayoutComponent,
        LoginComponent,
        SupervisorLayoutComponent,
        TechLayoutComponent,
    ],
    providers: [
        AuthService,
        AdminGuardService,
        constants,
        DataService,
        EditTicketsResolve,
        EncriptionService,
        {
            provide: LocationStrategy, useClass: HashLocationStrategy
        },
        GrocerGuardService,
        ReportsService,
        SupervisorGuardService,
        TechnicalGuardService,
        TicketsService,
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule { }
