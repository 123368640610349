import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { constants } from 'app/config/constants';


@Injectable()
export class TicketsService {
    private httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) { }

    public getTicketByID(idTicket: number) {
        const queryTicket = {
            query: 'CALL getTicketByID(?);',
            params: [idTicket]
        };

        return this.http.post<any>(constants.url + 'dynamic', queryTicket, this.httpOptions);
    }
}
